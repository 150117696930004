






































import Display from '@/utilities/Display'
import InsideContainer from '@/components/Containers/InsideContainer.vue'
import UploadBasic from '@/components/Uploads/UploadBasic.vue'
import Loading from '@/components/Modals/Loading.vue'

export default {
  name: 'Upload',
  components: {
    InsideContainer,
    UploadBasic,
    Loading,
  },
  data() {
    return {
      uploadId: '',
      show: false,
      name: 'click',
      childLoaded: true,
    }
  },
  computed: {
    mobile(): boolean { return Display.mobile(this.$vuetify) },
  },
  beforeMount() {
    this.uploadId = this.$route.params.uploadid
  },
  methods: {
    childLoading(loading) {
      this.childLoaded = loading
    },
  },
}
