<template>
  <v-overlay
    :value="show"
    :absolute="true"
    :opacity=".1"
  >
    <v-progress-circular
      indeterminate
      color="red"
      :size="50"
      :width="6"
    />
  </v-overlay>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
}
</script>
