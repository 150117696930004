



















/**
 * This component is used select photos on the device for upload.
 *
 * Selected filenames are stored in localStorage.uploadList
 *
 * Props:
 * uploadId the upload folder ID that is targeted for the selected pics
 *
 * Events:
 * change ()  conveys that files have been selected
 *
 * APIs:
 * None
 */

// eslint-disable-next-line import/extensions
import { UploadResponse, Upload, Map } from '@/interfaces/main.d.ts'
import Urls from '@/utilities/Url'
import Errors from '@/utilities/Errors'

export default {
  name: 'UploadBasic',
  props: {
    uploadId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      selectedItem: -1,
      upload: {} as Upload | undefined,
      items: [] as Array<Upload> | undefined,
    }
  },
  beforeMount(): void {
    this.fetchFolderPics()
      .then(() => {
        this.$emit('loading', false)
      })
      .catch(() => {
        this.$emit('loading', false)
      })
  },
  methods: {
    safeImage(item: Map): string {
      return (item.thumbnail_url) ? item.thumbnail_url : item.image_url
    },
    /**
     * Call books api
     */
    fetchFolderPics(): Promise<boolean> {
      return new Promise((resolve, reject) => {
        this.loading = true
        fetch(Urls.url('upload', { uploadid: this.uploadId }, { pics: 'y' }), {
          method: 'GET',
          headers: { Authorization: localStorage.tk },
        }).then((response: Response) => response.json())
          .then((upload: UploadResponse) => {
            this.loading = false
            if (upload.status !== 'Success') {
              reject(upload.message)
            } else {
              this.upload = upload.data
              this.items = upload.data ? upload.data.pics : undefined
              resolve(true)
            }
          })
          .catch((err: string) => {
            Errors.logError(err)
            reject()
          })
      })
    },
  },
}
